export const fetchAnimals = (token, pageNo = 1)=>{
    let url =`https://api.petfinder.com/v2/animals?organization=NJ523&status=adoptable&page=${pageNo}`;
    return fetch(url, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(response => response.json())
    ;
}

export const fetchToken = () => {
    let url = "http://njcattitude.org/token.php";
    return fetch(url)
        .then(response=>response.json());
}

export const negotiate = () => {
  let url = "https://api.petfinder.com/v2/oauth2/token";
  let data = {
    grant_type: "client_credentials",
    client_id: "NMwtTrrSG76t1Cy3hDWHlVe3x6JvAaibtBgNjRZyrQA4BMNwQ5",
    client_secret: "fZE5APwN8O6Az48bijC3KcPb38onEIDp13NBkOqW",
  };
  let poster = {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //mode: 'cors', // no-cors, cors, *same-origin
    //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    //redirect: 'follow', // manual, *follow, error
    //referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  };
  return fetch(url,poster)
    .then(response=>response.json());
}