import React from "react";

export const Links = (props) => {
  let data = [
    {
      url: "http://happypawsrescue.org/",
      label: "Happy Paws Rescue"
    },
    {
      url: "http://www.alleycat.org/",
      label: "Alley Cat Allies"
    },
    {
      url: "http://www.friendsofanimals.org/",
      label: "Friends of Animals"
    },
    {
      url:
        "https://bestfriends.org/our-work/supporting-network-partners/best-friend-dog-and-animal-adoption",
      label: "Best Friends Cranford"
    },
    {
      url: "http://www.coalitionforanimals.org/",
      label: "Coalition for Animals"
    },
    {
      url: "http://www.thebestlittlecathouseinpa.com/",
      label: "Best Little Cat House in PA"
    },
    {
      url: "https://www.pfaonline.org/about/",
      label: "People for Animals Robbinsville"
    },
    {
      url: "https://www.mycjvc.com",
      label: "Central Jersey Veterinary Clinic"
    },
    {
      url: "https://www.facebook.com/pages/Park-Pet-Palace/126179120769850",
      label: "Park Pet Palace Highland Park"
    },
    {
      url: "http://edisonnj.org/departments/animal_shelter/index.php",
      label: "Edison Animal Shelter"
    },
    {
      url:
        "https://www.twp.woodbridge.nj.us/256/Woodbridge-Animal-Shelter-Pet-Adoption-C",
      label: "Woodbridge Animal Shelter"
    },
    {
      url: "http://www.libertyhumane.org/",
      label: "Liberty Humane Society"
    },
    {
      url: "http://woodlandswildlife.org/",
      label: "Woodlands WildLife Refuge"
    },
    {
      url: "https://skylandssanctuary.org/",
      label: "Skylands Animal Sanctuary"
    },
    {
      url: "https://barnyardsanctuary.org/",
      label: "Barnyard Sanctuary"
    },
    {
      url: "http://www.xmission.com/~emailbox/catstuff.htm",
      label: "Feline Art (by Glenda Moore)"
    }
    /*
    { 
      url: "http://www.thecatsite.com/", 
      label: "The Cat Site" 
    },
    {
      url: "http://www.monmouthcountyspca.org/",
      label: "Monmouth County, SPCA"
    },
    {
      url: "http://members.petfinder.org/~NJ17/index1.htm",
      label: "People for Animals, Hillside"
    },
    {
      url: "http://www.petfinder.com/shelters/NJ470.html",
      label: "H.A.L.O.S."
    },
    { 
      url: "http://www.naturesrefuge.com/", 
      label: "Nature's Refuge" 
    },
    {
      url: "http://www.petfinder.com/shelters/pawsabilities.html",
      label: "Paws Abilities"
    },
    {
      url: "http://www.petfinder.com/shelters/NJ159.html",
      label: "Perth Amboy Animal Shelter"
    },
    {
      url: "http://www.petfinder.com/shelters/NJ302.html",
      label: "Puppy Love Pet Rescue"
    },
    {
      url: "http://www.petfinder.com/shelters/NJ502.html",
      label: "Save Us Dog Rescue"
    },
    {
      url: "http://www.furfriendsinneed.com/",
      label: "Fur Friends In Need"
    },
    */
  ];

  let links = data.map((p,i,a)=>{
    return (
      <div key={i} className="col-12">
        <a className="btn btn-sm links" target="blank" href={p.url}>{p.label}</a>
      </div>
    );
  });
  return (
    <div className="indent-top row">
      {links}
    </div>
  );
}
