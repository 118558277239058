import React from "react";

export const Adopt = ()=>{
    return (
      <div className="row">
        <div className="col-12 col-md-6 col-xl-12 indent-top">
          <center>
            <h1>Adoption</h1>
          </center>
        </div>
        <div className="col-12 col-md-6 col-xl-12 indent-top">
          <div className="message">
            To adopt any of our cats/kittens during this health emergency you must first
            complete and submit our Cat Adoption Form.

            We are only able to show cats/kittens to individuals/families whose Cat
            Adoption Form has been approved.

            For more details about our adoption process or if you have any questions
            please reach us via email at njcattitude@gmail.com
          </div>
          <h4>
            <a href="/docs/catadoption.docx">Adoption Form</a>
          </h4>
        </div>
      </div>
    );
};

