import React, {Component} from "react";
import rescued from '../assets/rescued.jpg'
import { Adopt } from "./adopt";

export class OurPets extends Component {
    render(){
        let rows = null;
        if (this.props.animals != null) {
            rows = this.props.animals.map((p, i) => {
                let imgsrc = null;
                if (p.photos != null && p.photos.length > 0 && p.photos[0].medium != null) {
                    imgsrc = <img style={{width: "100%"}} src={p.photos[0].medium} alt="" />;
                }
                return (
                    <div className="col-xs-12 col-sm-6 col-xl-3" key={i}>
                        <a href={p.url} target="blank">
                            <div className="petname">{p.name}</div>
                            <div>{imgsrc}</div>
                        </a>
                    </div>
                );
            });
        }

        return (
          <React.Fragment>
            <div className="row">
              <div className="col-12">
                <h5>
                  Thousands of homeless kittens &amp; cats rescued locally and
                  placed in forever homes. Have fun looking at the recently
                  rescued cuties waiting to purr in your ear.
              </h5>
              </div>
              <div className="col-12 col-xl-9">
                <img className="width100" src={rescued} alt="" />
                <br />
                <br />
              </div>
              <div className="col-12 col-xl-3">
                <Adopt />
                <br />
                <br />
              </div>
              <div className="col-12">
                <h5>Here are our adoptable animals</h5>
                <br />
              </div>
            </div>
            <div className="row ourpets">
              {rows}
            </div>
          </React.Fragment>
        );
    }
}

