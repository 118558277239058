import React from 'react';
import cover from '../assets/cover2.jpg';

export const About = ()=>{
    return (
        <div className="row">
            <div className="col-12 indent-top align-left">
                <h1 className="center">Our Mission</h1>
                We are Cattitude, Inc a New Jersey Non - Profit Cat
                Rescue &amp; Adoption Corporation. Our mission is the rescue and
                adoption of homeless and abandoned domestic cats by
                removing them from dangerous or otherwise inappropriate
                environments and providing foster care, veterinary care,
                socialization and any other appropriate intervention, as well
                as: facilitating the adoption of cats impounded in
                local municipal shelters participation in and promotion of spay
                / neuter programs designed to reduce the stray population
                providing information about pet safety, pet training, pet birth
                control, emergency care and otherwise promoting appropriate
                treatment of cats and the prevention of cruelty to all
                animals
                <br/> <br/>
                <ul>
                    <li>
                        Cattitude Inc is an All-Volunteer, Non-Profit NJ Cat Rescue &amp; Adoption Corporation established in 2007.
                    </li>
                    <li>
                        Cattitude Inc volunteers have rescued and found homes for thousands of homeless &amp; abandoned kittens and cats.
                    </li>
                    <li>
                        Cattitude Inc volunteers focus on rescuing abandoned and homeless orphan kittens, homeless nursing mom cats &amp; their babies, homeless pregnant cats - all in our local community.
                    </li>
                    <li>
                        Cattitude Inc has no paid employees and no "administrative fees" are ever deducted from any donations.   All donations to Cattitude Inc go directly to paying veterinary costs and other costs associated with saving the lives of homeless kittens &amp; cats.
                    </li>
                    <li>
                        Cattitude Inc only adopts kittens and cats who have already been spayed or neutered - vaccinated - tested negative for feline aids &amp; feline leukemia.
                    </li>
                    <li>
                        Cattitude Inc has maintained an Adoption Center in the Piscataway PetCo (1333 Centennial Avenue) since 2007.
                    </li>
                    <li>
                        Cattitude Inc volunteers do not bring kittens or cats from other states but focus on rescuing homeless kittens &amp; cats in our local community.
                    </li>
                </ul>
            </div>
        </div>
    );
}
